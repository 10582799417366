import React, { useState } from "react"
import * as S from "./charts-section.styles"
import { Container } from "@mui/material"
import ChartsTabs from "../../components/charts-tabs/charts-tabs.component"

const ChartsSection = ({ howLongTitle, howMuchTitle }) => {
  const [topTitle, setTopTitle] = useState(howLongTitle)
  return (
    <S.Wrapper>
      <Container>
        {topTitle && <S.Title>{topTitle}</S.Title>}
        <ChartsTabs
          handleChangeTitle={setTopTitle}
          howLongTitle={howLongTitle}
          howMuchTitle={howMuchTitle}
        />
      </Container>
        <div id="solution"/>
    </S.Wrapper>
  )
}

export default ChartsSection
