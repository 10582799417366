import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  z-index: 6;
`

export const Title = styled.h2`
  max-width: 680px;
  margin: auto;
  text-align: center;
  font-weight: 400;
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: ${({ theme }) => theme.typography.pxToRem(40)};
  color: rgba(13, 24, 39, 1);

  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(48)};
    line-height: ${({ theme }) => theme.typography.pxToRem(58)};
  }
`
