import styled from "@emotion/styled"
import { ButtonBase, Typography } from "@mui/material"
import HowMuch from "../../assets/how-much.svg"

export const Wrapper = styled.div`
  max-width: 910px;
  margin: auto;
  padding-top: 5rem;
`

export const TabWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
`

export const Tab = styled(ButtonBase)`
  min-width: 125px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  color: rgba(144, 144, 152, 1);
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  line-height: 32px;
  transition: 0.3s all ease;

  &.active {
    color: ${({ theme }) => theme.palette.text.content};
    > div {
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  &:hover {
    color: ${({ theme }) => theme.palette.text.content};
    > div {
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`

export const TabTitle = styled(Typography)``

export const TabIndicator = styled.div`
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: #909098;
  margin-top: 8px;
`

export const TabPanelWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 4.5rem;
  }
  margin-top: 1.75rem;
  //min-height: 220px;
`

export const HowMuchImage = styled(HowMuch)`
  //height: fit-content;
`
