import React, { useState, useEffect } from "react"
import * as S from "./line-chart.styles"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ScriptableContext,
} from "chart.js"
import { Line } from "react-chartjs-2"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
)

const LineChart = ({ name, years, labels }) => {
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      // tooltip: {
      //   enabled: false,
      // },
    },
    scales: {
      xAxes: {
        // title: {
        //   display: true,
        //   text: "Year",
        //   align: "start",
        //   position: "left",
        // },
        grid: {
          display: false,
          borderColor: "rgba(0, 0, 0, 0.15)",
          color: "rgba(0, 0, 0, 0.15)",
        },
        ticks: {
          font: {
            size: 11,
            family: "Inter",
          },
          color: ["#000000"],
        },
      },

      yAxes: {
        title: {
          display: true,
          text: "Years",
          align: "center",
          color: ["#000000"],
          font: {
            size: 11,
            family: "Inter",
          },
        },
        suggestedMin: 9.75,
        suggestedMax: 11.5,
        grid: {
          drawBorder: false,
          borderColor: "rgba(0, 0, 0, 0.15)",
          color: "rgba(0, 0, 0, 0.15)",
          stepSize: 0.5,
        },
        ticks: {
          font: {
            size: 11,
          },
          color: ["#000000"],
          stepSize: 0.25,
          callback: function (value, index) {
            return value % 1 === 0 ? value : " "
          },
        },
      },
    },
  }
  const data = {
    labels: labels,
    datasets: [
      {
        label: name,
        data: years,
        borderColor: ["#A506EF"],
        fill: true,
        pointBackgroundColor: "#FFFFFF",
        backgroundColor: context => {
          const ctx = context.chart.ctx
          const gradient = ctx.createLinearGradient(0, 0, 0, 200)
          gradient.addColorStop(0, "rgba(60, 121, 204, 1)")
          gradient.addColorStop(1, "rgba(165, 6, 239, 0.3)")
          return gradient
        },
      },
    ],
  }
  return (
    <S.Wrapper>
      <Line style={{ maxHeight: 220 }} type="" data={data} options={options} />
    </S.Wrapper>
  )
}

export default LineChart
