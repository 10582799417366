import React, { useState, useEffect } from "react"
import * as S from "./charts-table.styles"
import LineChart from "../line-chart/line-chart.component"
import { isBrowser } from "../../utils/utils"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { graphql, useStaticQuery } from "gatsby"

const ChartsTabs = ({ handleChangeTitle, howLongTitle, howMuchTitle }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (isBrowser) {
      gsap.registerPlugin(ScrollTrigger)
    }
    ScrollTrigger.create({
      trigger: ".wrapper",
      // markers: true,
      onEnter: () => setIsVisible(true),
    })
  }, [])

  const staticQuery = useStaticQuery(graphql`
    query {
      howMuch: file(relativePath: { eq: "how-much.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `)
  return (
    <S.Wrapper>
      <S.TabWrapper>
        <S.Tab
          disableRipple
          onClick={() => {
            setActiveTabIndex(0)
            handleChangeTitle(howLongTitle)
          }}
          className={activeTabIndex === 0 ? "active" : ""}
        >
          <S.TabTitle>How Long</S.TabTitle>
          <S.TabIndicator />
        </S.Tab>
        <S.Tab
          disableRipple
          onClick={() => {
            setActiveTabIndex(1)
            handleChangeTitle(howMuchTitle)
          }}
          className={activeTabIndex === 1 ? "active" : ""}
        >
          <S.TabTitle>How Much</S.TabTitle>
          <S.TabIndicator />
        </S.Tab>
      </S.TabWrapper>
      <S.TabPanelWrapper className="wrapper">
        {isVisible && activeTabIndex === 0 ? (
          <LineChart
            name="Years"
            years={[10.15, 10.57, 10.39, 10.58, 10.61, 10.64, 11.14, 10.9]}
            labels={[
              "2014",
              "2015",
              "2016",
              "2017",
              "2018",
              "2019",
              "2020",
              "2021",
            ]}
          />
        ) : (
          <S.HowMuchImage />
        )}
      </S.TabPanelWrapper>
    </S.Wrapper>
  )
}

export default ChartsTabs
