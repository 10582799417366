import React from "react"
import * as S from "./about-problem.styles"
import AboutHero from "../AboutHero"
import ChartsSection from "../ChartsSection"

const AboutProblem = ({
  txtLabel,
  title,
  txtButton,
  moleculaImage,
  extraText,
  withMolecules,
  steps,
  howLongTitle,
  howMuchTitle,
}) => {
  return (
    <S.Wrapper id="problem" name={"Problem"}>
      <AboutHero
        txtLabel={txtLabel}
        txtButton={txtButton}
        extraText={extraText}
        withMolecules={withMolecules}
        steps={steps}
        title={title}
        moleculaImage={moleculaImage}
      />
      <ChartsSection howLongTitle={howLongTitle} howMuchTitle={howMuchTitle} />
    </S.Wrapper>
  )
}

export default AboutProblem
