import styled from "@emotion/styled"
import Circle from "../../assets/graph.svg"
import Circle2 from "../../assets/circle.svg"
import { Typography } from "@mui/material"
import ProtonLight from "../../assets/proton-logo-light-blue.svg"

export const Wrapper = styled.div`
  position: relative;
  transition: 0.3s all ease;
`

export const CustomCircle = styled(Circle)`
  transition: 0.3s all ease;
  visibility: hidden;
  outline: none;
  width: 100%;
  height: 100%;
  .wrapper {
    cursor: pointer;
    &.top {
      :hover {
        circle {
          fill: url(#paint0_linear_2448_16422);
        }
      }
    }
    &.right {
      :hover {
        circle {
          fill: url(#paint1_linear_2448_16422);
        }
      }
    }
    &.left {
      :hover {
        circle {
          fill: url(#paint2_linear_2448_16422);
        }
      }
    }
    circle {
      fill: white;
    }
    path {
      fill: ${({ theme }) => theme.palette.primary.main};
    }
    :hover {
      path {
        fill: white;
      }
    }
  }
`

export const CustomCircle2 = styled(Circle2)`
  transition: 0.3s all ease;
  pointer-events: auto;
  outline: none;
  width: 100%;
  height: 100%;
  .wrapper {
    cursor: pointer;
    &.top {
      :hover {
        circle {
          fill: url(#paint0_linear_2448_16422);
        }
      }
    }
    &.right {
      :hover {
        circle {
          fill: url(#paint1_linear_2448_16422);
        }
      }
    }
    &.left {
      :hover {
        circle {
          fill: url(#paint2_linear_2448_16422);
        }
      }
    }
    circle {
      fill: white;
    }
    path {
      fill: ${({ theme }) => theme.palette.primary.main};
    }
    :hover {
      path {
        fill: white;
      }
    }
  }
`

export const WhiteCircle = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 100%;
  background-color: white;
  position: absolute;
  transition: 0.3s all ease;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down(520)} {
    width: 81px;
    height: 81px;
  }

  ${({ theme }) => theme.breakpoints.down(480)} {
    width: 74px;
    height: 74px;
  }

  ${({ theme }) => theme.breakpoints.down(430)} {
    width: 66px;
    height: 64px;
  }

  ${({ theme }) => theme.breakpoints.down(370)} {
    width: 59px;
    height: 59px;
  }

  ${({ theme }) => theme.breakpoints.down(355)} {
    width: 57px;
    height: 57px;
  }

  &:hover {
    background: linear-gradient(101.14deg, #3c79cc 8.85%, #a506ef 92.41%);
    svg {
      fill: white;
    }
  }

  &.top {
    left: 182.83px;
    top: 0;
    ${({ theme }) => theme.breakpoints.down(520)} {
      top: 0;
      left: 47%;
      transform: translateX(-50%);
    }
  }

  &.left {
    left: 74.41px;
    top: 278.15px;
    ${({ theme }) => theme.breakpoints.down(520)} {
      top: unset;
      bottom: 11%;
      left: 24%;
      transform: translateX(-50%);
    }
  }

  &.right {
    left: 296.08px;
    top: 278.15px;
    ${({ theme }) => theme.breakpoints.down(520)} {
      top: unset;
      bottom: 11%;
      left: 72%;
      transform: translateX(-50%);
    }
  }
`

export const CenterWrapper = styled.div`
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  top: 53%;
  left: 49.7%;
  transform: translate(-50%, -50%);
  transition: 0.3s all ease;
  display: flex;
  justify-content: center;
  max-width: 75%;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    max-width: unset;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-top: 20px;
    top: 50%;
    left: 50%;
  }
`

export const CenterTitle = styled(Typography)`
  position: absolute;
  top: 66%;
  left: 49%;

  visibility: ${props => (props.show ? "visible" : "hidden")};
  transform: translate(-50%, -50%);

  &.top-title {
    top: 38%;

    padding-bottom: 0.5rem;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 0.9rem;
    top: 70%;
  }

  ${({ theme }) => theme.breakpoints.down(450)} {
    font-size: 0.9rem;
    top: 75%;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5.5rem;
`
export const ProtonLogo = styled(ProtonLight)`
  width: 90px;
  height: 90px;
  background-color: white;
  border-radius: 50%;
  /* visibility: ${props => (props.show ? "visible" : "hidden")}; */
  ${({ theme }) => theme.breakpoints.down(470)} {
    width: 65px;
    height: 65px;
  }
  ${({ theme }) => theme.breakpoints.down(350)} {
    width: 55px;
    height: 55px;
  }
`
